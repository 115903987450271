<template>
  <div>
    <apexchart
      type="line"
      :options="chartOptions"
      :series="series"
      :height="height"
      :width="width"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';

export default {
  name: 'BarChart',
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    height: {
      type: [String, Number],
      default() {
        return 400;
      }
    },
    width: {
      type: [String, Number],
      default() {
        return 500;
      }
    }
  },
  data() {
    return {
      series: [
        {
          name: 'Cost',
          data: [] // Dynamic data from props
        }
      ],
      chartOptions: {
        chart: {
          type: 'line',
          width: '100%',
          height: '100%',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        colors: ['#436de7'],
        dataLabels: {
          enabled: false,
          formatter: (value) => {
            return value.toLocaleString(); // Show full value with commas if applicable
          },
          style: {
            colors: ['#436de7'],
            fontSize: '10px',
          }
        },
        stroke: {
          curve: 'smooth',
          width: 4,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#436de7'], // Second color of gradient
            inverseColors: false,
            opacityFrom: 0.8,
            opacityTo: 0.2,
            stops: [0, 100],
          }
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        markers: {
          size: 5
        },
        xaxis: {
          categories: [], // Dynamic categories for dates

          tooltip: {
            enabled: false, // Disables the tooltip at the bottom on the x-axis
          }, labels: {
            // show: false,
            rotate: -45,
            formatter: function (value) {
              return value;
            }
          }
        },
        yaxis: {
          show: false,
          labels: {
            formatter: (value) => {
              // if (value >= 1000) return (value / 1000).toFixed(0) + "K"; // Round to whole thousands
              return value;
            },
            style: {
              fontSize: '10px'
            }
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: 0,
          offsetX: 0
        },
        tooltip: {
          x: {
            formatter: (val, opts) => {
              // Access start and end date from data
              const startDate = this.data[opts.dataPointIndex].period_start_date;
              const endDate = this.data[opts.dataPointIndex].period_end_date;

              // Format dates with time using moment.js (if imported)
              const startFormatted = moment(startDate).format("MM/DD");
              const endFormatted = moment(endDate).format("MM/DD");

              return `${startFormatted} - ${endFormatted}`;
            },
          },
          y: {
            formatter: (value) => {
              // Format value with commas for thousands
              return value.toLocaleString(); // Show full value with commas
            },
          },

        }
      }
    };
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        this.updateChartData(newData);
      }
    }
  },
  methods: {
    xAxisValues(start, end) {
      const startDate = new Date(start)
      const endDate = new Date(end)
      let dateformat = "MMM DD";
      if (startDate.getFullYear() !== endDate.getFullYear()) {
        dateformat = "YYYY-MMM DD";
      }
      const initDate = moment(start).format(dateformat)
      return `${initDate}`;
    },
    updateChartData(data) {
      // Set the series data for y-axis (total_fuel_cost)
      this.series = [
        {
          name: 'Cost',
          data: data.map(item => parseFloat(item.total_fuel_cost.replace(/,/g, '')))
        }
      ];
      this.chartOptions.xaxis.categories = data.map(item => this.xAxisValues(item.period_start_date, item.period_end_date));
    }
  }
};
</script>